<template>
  <el-dialog
    title="余额充值"
    :close-on-click-modal="false"
    :visible.sync="visible"
    class="missionPart"
    append-to-body
  >
    <div class="tablePart">
      <el-table :data="dataList" ref="dataList">
        <el-table-column
          prop="companyName"
          label="公司名字"
          header-align="center"
          align="center"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="员工名字"
          header-align="center"
          align="center"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="rechargeMoney"
          label="金额"
          header-align="center"
          align="center"
          width="400"
        >
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.rechargeMoney"
              :min="0"
              :step="1"
              :precision="2"
              @change="
                scope.row.rechargeMoney = scope.row.rechargeMoney.toFixed(2)
              "
            ></el-input-number>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { dialogMixin, normal } from '@/mixins';
export default {
  mixins: [dialogMixin, normal],
  data() {
    return {
      visible: false,
      dataForm: {
        id: 0,
        companyId: null,
        employeesId: null,
        remarks: '',
        rechargeMoney: 0,
        companyEmployees: '',
      },
      dataList: [],
      dataRule: {
        rechargeMoney: [
          { required: true, message: '充值金额不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  created() {},
  methods: {
    init(row) {
      console.log(row);
      this.dataList = JSON.parse(JSON.stringify(row)).map((item) => {
        return {
          companyName: item.companyName,
          name: item.name,
          rechargeMoney: 0.01,
          uuid: item.uuid,
          remarks: '',
          employeesId: item.id,
          companyId: item.companyId,
        };
      });
      console.log(this.dataList);
      this.visible = true;
      // this.$nextTick(() => {
      //   this.$refs['dataForm'].resetFields();
      // });
    },
    // 表单提交
    dataFormSubmit() {
      this.$http({
        url: `/tc/employees/userRecharge`,
        method: 'post',
        data: {
          recharges: this.dataList,
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500,
          });
          this.visible = false;
          this.$emit('success');
        }
      });
    },
  },
};
</script>
